//Replacement for the THREE BasicMaterial adding cut plane support

import * as THREE from "three";
import { ShaderChunks as chunks } from './ShaderChunks';
import basic_vert from './shaders/basic_vert.glsl';
import basic_frag from './shaders/basic_frag.glsl';

let newThreejsUniforms = [];
/// #if threejsVersion != 'R71'
{
    newThreejsUniforms = [
        THREE.UniformsLib[ "specularmap" ],
        THREE.UniformsLib[ "envmap" ],
        THREE.UniformsLib[ "lightMap" ],
        {
            "offsetRepeat": { type: "v4", value: new THREE.Vector4(0, 0, 1, 1) },
            "morphTargetInfluences": { type: "f", value: 0 }
        }
    ];
}
/// #endif
export let BasicShader = {

    uniforms: THREE.UniformsUtils.merge( [

        THREE.UniformsLib[ "common" ],
        THREE.UniformsLib[ "fog" ],
        THREE.UniformsLib[ "shadowmap" ],
        chunks.CutPlanesUniforms,
        chunks.IdUniforms,
        chunks.ThemingUniform,
        chunks.PointSizeUniforms,
        chunks.WideLinesUniforms, 
        chunks.DepthTextureTestUniforms,
        ...newThreejsUniforms
    ] ),

    vertexShader: basic_vert,
    fragmentShader: basic_frag

};

THREE.ShaderLib['firefly_basic'] = BasicShader;
